import { BuilderComponent } from '@builder.io/react'
import { Amplify } from 'aws-amplify'
import { graphql } from 'gatsby'
import React from 'react'
// import { Link } from 'gatsby'
// import { StaticImage } from 'gatsby-plugin-image'
// import Layout from '../components/ui/layout/Layout'
// import Seo from '../components/util/Seo'
// import App from './App'
import awsconfig from '../aws-exports'

if (process.env.NODE_ENV === 'development') {
  awsconfig.aws_cloud_logic_custom = awsconfig.aws_cloud_logic_custom.map(
    (config: { name: string; endpoint: string }) => {
      if (config.name === 'wwwREST') {
        config.endpoint = 'http://localhost:3000'
      }
      return config
    }
  )
}
Amplify.configure(awsconfig)

const Root = props => {
  const content = props.data?.allBuilderModels.page[0]?.content

  return (
    <>
      <BuilderComponent content={content} model="page" />
    </>
  )
}

export default Root

export const query = graphql`
  query ($path: String!) {
    allBuilderModels {
      page(target: { urlPath: $path }, options: { cachebust: true }) {
        content
      }
    }
  }
`
